<template>
   <v-app>
      <home-system-bar />

      <home-app-bar />

      <home-view />
      <notification />

      <home-footer />

      <!-- <home-settings /> -->
   </v-app>
</template>

<script>
import { flatten } from '@/utils/nirtara'
export default {
   name: 'HomeLayout',

   components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeFooter: () => import('@/layouts/home/Footer'),
      // HomeSettings: () => import('@/layouts/home/Settings'),
      HomeSystemBar: () => import('@/layouts/home/SystemBar'),
      HomeView: () => import('@/layouts/home/View'),
      Notification: () => import('@/layouts/home/Notification'),
   },

   created () {
      this.$waitSetting(zone => {
         this.$socket.on(`server/${this.$store.state.zone}/Message`, (data) => {
            if (data.Content && data.Content.length && data.Content[0].kind === 'Global') Object.assign(this.$store.state.setting.global, flatten(data.Content[0]))
            else if (data.Server) {
               if (data.welcome) {
                  this.$store.commit('x-forwarded-for', data.Server[0].ip)
                  console.log(data.welcome)
               }
               if (data.online) {
                  const user = this.$store.state.user
                  user.id === data.Server[0].id && data.online !== user.online && this.$store.dispatch('logout').then(() => { this.$route.name !== 'Home' && this.$router.push({ name: 'Home' }) })
               }
            } else console.log(`created (server/${zone}/Message)`, data)
         })
      })
   },
   destroyed () {
      this.$socket.off(`server/${this.$store.state.zone}/Message`)
   },
}
</script>
